<template>
  <el-card class="box-card">
    <vab-query-form-left-panel :span="12">
      <el-button class="button" icon="el-icon-plus" type="primary" @click="handleEdit" size="small">添加</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        size="small"
        style="width: 100%">
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          label="头衔"
          prop="name"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="积分/成长值"
          prop="credit"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="折扣"
          prop="discount"
      >
        <template #default="{ row }">
          <span v-if="row.discount">{{ row.discount }}%</span>
          <span v-else>-%</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="等级图" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-if="imgShow" :src="row.image" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="简单描述"
          prop="descr"
      >
        <template #default="{ row }">
          <span v-if="row.descr">{{ row.descr }}</span>
          <span v-else>暂无简介</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="详细描述"
          prop="info"
      >
        <template #default="{ row }">
          <span v-if="row.info">{{ row.info }}</span>
          <span v-else>暂无详情</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </el-card>
</template>
<script>
import Edit from './components/level_Edit'
import {apiGetUserLevel, apiDeleteUserLevel} from '@/request/api'

export default {
  components: {Edit},
  props: [],
  data() {
    return {
      imgShow: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        role: 'user',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //新增、编辑
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    //删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteUserLevel({role: 'user', id: row.id}).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    //获取等级列表
    async fetchData() {
      this.listLoading = true
      apiGetUserLevel(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>
