var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"box-card"},[_c('vab-query-form-left-panel',{attrs:{"span":12}},[_c('el-button',{staticClass:"button",attrs:{"icon":"el-icon-plus","type":"primary","size":"small"},on:{"click":_vm.handleEdit}},[_vm._v("添加")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"size":"small"}},[_c('el-table-column',{attrs:{"align":"center","label":"ID","prop":"id","width":"80"}}),_c('el-table-column',{attrs:{"align":"center","label":"头衔","prop":"name"}}),_c('el-table-column',{attrs:{"align":"center","label":"积分/成长值","prop":"credit"}}),_c('el-table-column',{attrs:{"align":"center","label":"折扣","prop":"discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.discount)?_c('span',[_vm._v(_vm._s(row.discount)+"%")]):_c('span',[_vm._v("-%")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"等级图","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.imgShow)?_c('el-image',{staticClass:"commodity_pic",attrs:{"src":row.image}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"简单描述","prop":"descr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.descr)?_c('span',[_vm._v(_vm._s(row.descr))]):_c('span',[_vm._v("暂无简介")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"详细描述","prop":"info"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.info)?_c('span',[_vm._v(_vm._s(row.info))]):_c('span',[_vm._v("暂无详情")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("修改")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","align":"center","current-page":_vm.queryForm.page,"layout":_vm.layout,"page-size":_vm.queryForm.pageSize,"total":_vm.count},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}}),_c('Edit',{ref:"Edit",on:{"fetch-data":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }