<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="头衔" prop="name">
        <el-input v-model="form.name" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="积分/成长值" prop="credit">
        <el-input type="number" v-model="form.credit" controls-position="right" :min="1" size="small"
                  style="width: 100%">
          <template slot="append">分/元</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="享折扣" prop="discount">
        <el-input type="number" v-model="form.discount" step-strictly controls-position="right" :min="1" size="small"
                  style="width: 100%">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="等级图" prop="image">
        <el-button v-if="!form.image" @click="showSelect = true">文件选择器</el-button>
        <img
            v-else
            class="avatar"
            :src="form.image"
            style="display: block; height: 100px"
        />
        <fileSelect :visible.sync="showSelect" append-to-body state="image" title="标题"
                    @confirm="confirmHandle"></fileSelect>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="简单描述" prop="descr">
        <el-input v-model="form.descr" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="详细描述" prop="info">
        <el-input
            type="textarea"
            size="small"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="form.info">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {baseURL} from '@/config'
import fileSelect from "@/views/components/fileSelect";
import {apiUpdateUserLevel} from '@/request/api'

export default {
  name: 'AdminEdit',
  components: {fileSelect},
  data() {
    return {
      picApi: baseURL + '/base/upload/upload-file',
      coustate: true,
      form: {
        id: '',
        role: 'user',
        name: '',
        discount: '',
        credit: '',
        descr: '',
        image: '',
        info: '',
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '请输入头衔'},
        ],
        credit: [
          {required: true, trigger: 'blur', message: '请输入积分/成长值'},
        ],
        discount: [
          {required: true, trigger: 'blur', message: '请输入享折扣'},
          {pattern: /^(0|[1-9]\d?|100)$/, message: '范围在0-100', trigger: 'blur'}
        ],
        image: [
          {required: true, trigger: 'blur', message: '请上传图片'},
        ],
      },
      title: '',
      dialogFormVisible: false,
      showSelect: false,
    }
  },
  created() {
  },
  methods: {
    confirmHandle(e) {
      if (e[0].origin) {
        this.form.image = e[0].origin
      }
      this.showSelect = false
    },
    handlePic(res) {
      console.log(res)
      const {code, data} = res
      if (code == 200) {
        this.form.pic = data
      }
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
        this.form.role = 'user'
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      if (this.form.pic == '') {
        this.$message.error('请选择等级图!', 'success')
        return
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdateUserLevel(this.form).then(res => {
            const that = this
            if (res.code == 200) {
              this.$message({
                message: this.title + '成功',
                type: 'success'
              });
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message({
                message: this.title + '失败',
                type: 'error'
              });
              this.close()
            }
          })

        } else {
          return false
        }
      })
    },
  },
}
</script>
